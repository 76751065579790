import {Component, OnInit, ViewChild} from '@angular/core';
import {TableService} from '../../shared/table.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProgressService} from '../../core/progress/progress.service';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {AgencyService} from '../shared/agency.service';
import {Agent} from '../../agents/shared/agent';
import {Agency} from '../shared/agency';
import {UpdateComponent} from '../update/update.component';


@Component({
    selector: 'app-agencies-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

    agencies;

    displayedColumns: string[] = ['name', 'actions'];
    tableFilter: string;

    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private tableService: TableService,
        public progress: ProgressService,
        public dialog: MatDialog,
        public agenciesService: AgencyService
    ) {
        this.tableFilter = this.tableService.agenciesList.filter;
    }

    ngOnInit() {

        this.getAgencies();

    }

    getAgencies() {
        this.progress.show();
        this.agenciesService.query().subscribe(response => {
            this.progress.hide();
            this.agencies = new MatTableDataSource<Agency>(response);
            this.agencies.sort = this.sort;
        });
    }

    applyFilter(filterValue: string) {
        if (filterValue) {
            this.agencies.filter = filterValue.trim().toLowerCase();
        }

    }

    add() {
        let agency = new Agency();
        const dialogRef = this.dialog.open(UpdateComponent, {
            data: agency,
            width: '600px',
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response && response.id) {
                let data = this.agencies.data;
                data.push(response);
                this.agencies.data = data;

            }
        });
    }


    edit(agency) {
        const dialogRef = this.dialog.open(UpdateComponent, {
            data: agency,
            width: '600px',
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response && response.id) {
                let data = this.agencies.data;
                data.splice(data.indexOf(agency), 1);
                data.push(response);
                this.agencies.data = data;

            }
        });
    }

    delete(agency) {
        if (!confirm('Are you sure you want to delete this agency? All associated agents will be deleted as well.')) {
            return;
        }
        this.progress.show();
        this.agenciesService.delete(agency.id).subscribe(response => {
            this.progress.hide();
            let data = this.agencies.data;
            data.splice(data.indexOf(agency), 1);
            this.agencies.data = data;
        });
    }



}
