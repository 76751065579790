import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenusListComponent} from './menus-list/menus-list.component';
import {MenusComponent} from './menus/menus.component';
import {SharedModule} from '../shared/shared.module';
import {MenusRoutingModule} from './menus-routing.module';
import {MenusUpdateComponent} from './menus-update/menus-update.component';
import {DragulaModule, DragulaService} from 'ng2-dragula';
import {CategoryListComponent} from './category-list/category-list.component';
import {MenuItemAddComponent} from './menu-item-add/menu-item-add.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MenusViewComponent } from './menus-view/menus-view.component';
import { CategoryViewComponent } from './category-view/category-view.component';
import { MenusAddComponent } from './menus-add/menus-add.component';
import { GlasspourComponent } from './glasspour/glasspour.component';
import { CategoryReorderComponent } from './category-reorder/category-reorder.component';
import { AgentComponent } from './agent/agent.component';
import { MenuItemEditComponent } from './menu-item-edit/menu-item-edit.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MenusRoutingModule,
        DragulaModule,
        NgbModule
    ],
    providers: [
        DragulaService
    ],
    declarations: [MenusListComponent, MenusComponent, MenusUpdateComponent, CategoryListComponent, MenuItemAddComponent, MenusViewComponent, CategoryViewComponent, MenusAddComponent, GlasspourComponent, CategoryReorderComponent, AgentComponent, MenuItemEditComponent],
    entryComponents: [MenuItemAddComponent, MenusAddComponent, AgentComponent, MenuItemEditComponent]
})
export class MenusModule {
}
