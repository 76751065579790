import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ProductsComponent} from './products/products.component';
import {AppRoutingModule} from './app-routing.module';

import {FormsModule} from '@angular/forms';
import {CoreModule} from './core/core.module';
import {ToastrModule} from 'ngx-toastr';

import {Ng4LoadingSpinnerModule} from 'ng4-loading-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {SharedModule} from './shared/shared.module';
import { LoginComponent } from './core/login/login.component';



@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CoreModule,
        AppRoutingModule,
        SharedModule.forRoot(),
        FormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        Ng4LoadingSpinnerModule.forRoot()
    ],
    exports: [
        AppRoutingModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
