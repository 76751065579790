import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../shared/product.service';
import {Product} from '../shared/product';
import {ProducerService} from '../../producers/shared/producer.service';
import {AppellationService} from '../../appellations/shared/appellation.service';
import {MenuService} from '../../menus/shared/menu.service';
import {Producer} from '../../producers/shared/producer';
import {Appellation} from '../../appellations/shared/appellation';
import {Menu} from '../../menus/shared/menu';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {AgentsService} from '../../agents/shared/agents.service';
import {FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import {ProgressService} from '../../core/progress/progress.service';

@Component({
    selector: 'app-products-update',
    templateUrl: './products-update.component.html',
    styleUrls: ['./products-update.component.scss']
})
export class ProductsUpdateComponent implements OnInit {

    product: Product = new Product();
    producers: Array<Producer>;
    appellations: Array<Appellation>;
    menus: Array<Menu>;
    producerSearch;
    appellationSearch;
    productSearch;
    agentSearch;
    associatedMenus = {};
    type: string;
    form: FormGroup;
    proxyValue: any;
    producer: Producer;
    filteredProducers: Observable<Producer[]>;
    filteredProducts: Observable<Product[]>;
    filteredAppellations: Observable<Appellation[]>;
    categories = {};
    fontSize = 12;
    chains = [{id: 'keg', name: 'Keg'}, {id: 'milestones', name: 'Milestones'}];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private productService: ProductService,
        private producerService: ProducerService,
        private appellationService: AppellationService,
        private menuService: MenuService,
        private toastr: ToastrService,
        private progress: ProgressService,
        private agentService: AgentsService,
        private fb: FormBuilder
    ) {

        this.form = this.fb.group({
            id: [''],
            type: [''],
            name: [''],
            producer: [''],
            appellation: [''],
            description: [''],
            fname: [''],
            tap: [0],
            glass: [0],
            short_name: [''],
            chain: [''],
            label: [''],
            bottle: [''],
            menuItems: this.fb.array([
            ])
        });

        this.filteredProducers = this.form
            .get('producer')
            .valueChanges
            .pipe(
                debounceTime(300),
                tap(() => {
                    this.producer = null;
                }),
                switchMap(value => this.producerService.autocomplete({query: value}))
            );

        this.filteredAppellations = this.form
            .get('appellation')
            .valueChanges
            .pipe(
                debounceTime(300),
                tap(() => {
                    this.producer = null;
                }),
                switchMap(value => this.appellationService.autocomplete({query: value}))
            );

        this.filteredProducts = this.form
            .get('name')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => this.productService.autocomplete({query: value}))
            );
    }

    addMenuItemFormGroup(data): FormGroup {
        return this.fb.group({
            old_cost: [data.old_cost],
            cost: [data.cost],
            old_price: [data.old_price],
            price: [data.price],
            '1oz': [data['1oz']],
            '2oz': [data['2oz']],
            '3oz': [data['3oz']],
            '6oz': [data['6oz']],
            '32oz': [data['32oz']],
            menu_id: [data.menu_id],
            category_id: [data.category_id],
            name: [data.name],
            active: [data.active],
            id: [data.id],
            glass_pour: [data.glass_pour],
            glass_pour_2: [data.glass_pour_2],
            chain: [data.chain]
        });

    }

    ngOnInit() {
        this.progress.show();

        let id = this.route.snapshot.paramMap.get('id');
        this.type = this.route.snapshot.paramMap.get('type');

        // we want to query all the menus
        let menusObservable = this.menuService.query();

        // if there is an id, this will be an edit request
        if (id) {
            // query the product
            let productObservable = this.productService.get(Number(id));

            // this is going to product an observable that will resolve once both the product and the menus have returned
            let value = forkJoin(productObservable, menusObservable).pipe(map(([product, menus]) => {
                return {product, menus};
            }));

            // now that we have both the product and the menus start setting it up
            value.subscribe(response => {
                this.progress.hide();

                let product = response.product;
                this.type = product.type;
                this.form.patchValue({
                    type: product.type,
                    name: product.name,
                    producer: product.producer,
                    appellation: product.appellation,
                    description: product.description,
                    fname: product.fname,
                    id: product.id,
                    tap: product.tap,
                    glass: product.glass,
                    short_name: product.short_name,
                    chain: product.chain,
                    bottle: product.bottle,
                    label: product.label
                });
                this.productSearch = {name: this.product.name};
                this.producerSearch = this.product.producer;
                this.appellationSearch = this.product.appellation;

                for (let menuItem of product.menu_items) {
                    this.associatedMenus[menuItem.menu_id] = menuItem;
                }

                let menus = response.menus.filter(menu => {
                    return menu.type === this.type;
                });

                this.menus = menus.sort((n1, n2) => {
                    if (n1.name === n2.name) {
                        return 0;
                    }
                    return n1.name < n2.name ? -1 : 1;
                });


                for (let menu of this.menus) {

                    if (!this.associatedMenus[menu.id]) {
                        this.associatedMenus[menu.id] = {active: false, menu_id: menu.id, product_id: this.product.id};
                    }
                    this.categories[menu.id] = menu.categoryList;

                    (<FormArray>this.form.get('menuItems')).push(this.addMenuItemFormGroup({
                        active: this.associatedMenus[menu.id].active,
                        menu_id: this.associatedMenus[menu.id].menu_id,
                        category_id: this.associatedMenus[menu.id].category_id,
                        '1oz': this.associatedMenus[menu.id]['1oz'],
                        '2oz': this.associatedMenus[menu.id]['2oz'],
                        '3oz': this.associatedMenus[menu.id]['3oz'],
                        '6oz': this.associatedMenus[menu.id]['6oz'],
                        '32oz': this.associatedMenus[menu.id]['32oz'],
                        price: this.associatedMenus[menu.id].price,
                        name: menu.name,
                        id: this.associatedMenus[menu.id].id,
                        glass_pour: this.associatedMenus[menu.id].glass_pour,
                        glass_pour_2: this.associatedMenus[menu.id].glass_pour_2,
                        old_price: this.associatedMenus[menu.id].old_price,
                        old_cost: this.associatedMenus[menu.id].old_cost,
                        cost: this.associatedMenus[menu.id].cost,
                        chain: menu.chain

                    }));

                }

            });

        } else {
            this.form.controls['type'].setValue(this.type);

            menusObservable.subscribe(response => {
                // filter the menus to only include the type
                let menus = response.filter(menu => {
                    return menu.type === this.type;
                });
                // sort the menus by name
                this.menus = menus.sort((n1, n2) => {
                    if (n1.name === n2.name) {
                        return 0;
                    }
                    return n1.name < n2.name ? -1 : 1;
                });



                // we're creating a map of the menus
                for (let menu of this.menus) {
                    if (!this.associatedMenus[menu.id]) {
                        this.associatedMenus[menu.id] = {active: false, menu_id: menu.id, product_id: this.product.id, category_id: null};

                        this.categories[menu.id] = menu.categoryList;
                        // this.product.menu_items.push(this.associatedMenus[menu.id]);

                        // this.form.menu_items.push(this.fb.group)
                        /*this.menuItems.push(this.fb.group({
                            active: [false],
                            menu_id: [menu.id],
                            product_id: [123],
                            category_id: [null],
                            price: [0]
                        }));
                        */
                        (<FormArray>this.form.get('menuItems')).push(this.addMenuItemFormGroup({
                            chain: menu.chain,
                            active: false, menu_id: menu.id, category_id: null, name: menu.name}));
                        // this.menuItems.push(this.addMenuItemFormGroup());

                    }

                }

            });
        }


    }

    displayFn(user?): string | undefined {
        return user ? user.name : undefined;
    }



    // appellations typeahead
    searchAgents = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            // tap(() => this.product.name = null),
            switchMap(term =>
                term === '' ? [] :
                    this.agentService.autocomplete({query: term}).pipe(
                        catchError(() => {
                            return of([]);
                        }))
            )
        )

    // save the product
    save() {
        let product = this.form.value;


        if (product.producer) {
            product.producer_id = product.producer.id;
        }

        if (product.appellation) {
            product.appellation_id = product.appellation.id;
        }


        this.progress.show();
        this.productService.update(product).subscribe(response => {
            this.toastr.success('Product saved');
            this.progress.hide();
            this.router.navigate(['/products/' + response.type]);

        }, error => {
            this.progress.hide();
            this.toastr.error('Could not save product');
        });
    }

    saveProducer() {
        let name = this.form.get('producer').value;
        let newProducer = new Producer();
        newProducer.name = name;
        this.producerService.update(newProducer, false).subscribe(response => {
            this.form.controls['producer'].setValue(response);
        });
    }

    saveAppellation() {
        let name = this.form.get('appellation').value;
        let newAppellation = new Appellation();
        newAppellation.name = name;
        this.appellationService.update(newAppellation, false).subscribe(response => {
            this.form.controls['appellation'].setValue(response);
            // this.form.setValue({appellation: response});
        });
    }

    upload(event, field) {
        this.form.get(field).setValue(event);
    }

}
