import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ProgressService} from './progress/progress.service';
import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private thinking: ProgressService,
        private auth: AuthService

    ) {}

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // handle your auth error or rethrow
        if (err.status === 401 || err.status === 403 || (err.status === 500 && err.error.message === 'Expired token')) {
            // navigate /delete cookies or whatever
            this.router.navigateByUrl('/');
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to
            // handle it as well.
            this.auth.logout();
            this.thinking.hide();
            return of(err.message);
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = localStorage.getItem('id_token');

        if (idToken) {
            const cloned = req.clone({
                headers: req.headers.set('Authorization',
                    'Bearer ' + idToken)
            });

            return next.handle(cloned).pipe(catchError(x => this.handleAuthError(x)));
        } else {
            return next.handle(req).pipe(catchError(x => this.handleAuthError(x)));
        }
    }
}
