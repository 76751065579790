import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Appellation} from './appellation';
import {ExceptionService} from '../../core/exception.service';
import {HttpBase} from '../../shared/http-base';

@Injectable({
    providedIn: 'root'
})
export class AppellationService extends HttpBase<Appellation> {

    constructor(
        http: HttpClient,
        exception: ExceptionService
    ) {
        super(http, exception, '/appellations', false);
    }



}

