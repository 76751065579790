import {Component, OnInit, ViewChild} from '@angular/core';
import {AgentsService} from '../shared/agents.service';
import {Agent} from '../shared/agent';
import {TableService} from '../../shared/table.service';
import {Producer} from '../../producers/shared/producer';
import {ProducerUpdateComponent} from '../../producers/producer-update/producer-update.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewComponent} from '../view/view.component';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Appellation} from '../../appellations/shared/appellation';
import {ProgressService} from '../../core/progress/progress.service';
import {UpdateComponent} from '../update/update.component';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

    agents;

    displayedColumns: string[] = ['agency', 'name', 'region', 'phone', 'email', 'actions'];
    tableFilter: string;

    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private agentsService: AgentsService,
        private tableService: TableService,
        private modalService: NgbModal,
        public progress: ProgressService,
        public dialog: MatDialog

    ) {
        this.tableFilter = this.tableService.agentsList.filter;
    }

    ngOnInit() {
        this.getAgents();
    }

    getAgents() {
        this.progress.show();
        this.agentsService.query()
            .subscribe(response => {
                this.progress.hide();
                this.agents = new MatTableDataSource<Agent>(response);

                this.agents.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case 'agency.name':
                            if (item.agency) {
                                return item.agency.name;
                            }
                            break;
                        case 'region.name':
                            if (item.region) {
                                return item.region.name;
                            }
                            break;
                        default:
                            return item[property];
                    }
                };

                this.agents.sort = this.sort;

                this.agents.filterPredicate = (data, filter: string) => {
                    const accumulator = (currentTerm, key) => {
                        if (key === 'agency' && data.agency) {
                            return currentTerm + data.agency.name;
                        }
                        if (key === 'region' && data.region) {
                            return currentTerm + data.region.name;
                        }
                        return currentTerm + data[key];

                    };
                    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                    // Transform the filter by converting it to lowercase and removing whitespace.
                    const transformedFilter = filter.trim().toLowerCase();
                    return dataStr.indexOf(transformedFilter) !== -1;
                };

            });
    }

    applyFilter(filterValue: string) {
        if (filterValue) {
            this.agents.filter = filterValue.trim().toLowerCase();
        }

    }

    add() {
        let agent = new Agent();
        const dialogRef = this.dialog.open(UpdateComponent, {
            data: agent,
            width: '600px',
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response && response.id) {
                let data = this.agents.data;
                data.push(response);
                this.agents.data = data;

            }
        });
    }

    view(agent) {
        const dialogRef = this.dialog.open(ViewComponent, {
            data: agent,
            width: '600px',
        });
    }

    edit(agent) {
        const dialogRef = this.dialog.open(UpdateComponent, {
            data: agent,
            width: '600px',
        });

        dialogRef.afterClosed().subscribe(newAgent => {
            if (newAgent && newAgent.id) {
                let data = this.agents.data;
                data.splice(data.indexOf(agent), 1);
                data.push(newAgent);
                this.agents.data = data;

            }
        });
    }

    delete(agent) {
        if (!confirm('Are you sure you want to delete this agent?')) {
            return;
        }
        this.progress.show();
        this.agentsService.delete(agent.id).subscribe(response => {
            this.progress.hide();
            let data = this.agents.data;
            data.splice(data.indexOf(agent), 1);
            this.agents.data = data;
        });
    }

}
