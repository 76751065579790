import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductsComponent} from './products.component';
import {SharedModule} from '../shared/shared.module';
import { ProductsUpdateComponent } from './products-update/products-update.component';
import {ProductsRoutingModule} from './products-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';



@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        ProductsRoutingModule,
        NgbModule
    ],
    declarations: [ProductsComponent, ProductsUpdateComponent]
})
export class ProductsModule {
}
