import { Injectable } from '@angular/core';
import {HttpBase} from './http-base';
import {Region} from './region';
import {HttpClient} from '@angular/common/http';
import {ExceptionService} from '../core/exception.service';

@Injectable({
  providedIn: 'root'
})
export class RegionsService extends HttpBase<Region> {

  constructor(
      http: HttpClient,
      exception: ExceptionService
  ) {
    super(http, exception, '/regions', false);
  }
}
