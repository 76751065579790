import {Component, Input, OnInit} from '@angular/core';
import {ProgressService} from './progress.service';

@Component({
    selector: 'app-thinking',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {

    show: boolean;

    constructor(
        private thinkService: ProgressService
    ) {
    }

    ngOnInit() {

        this.thinkService.countdownEnd$.subscribe((show) => {
            this.show = show;
        });
    }


}
