import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {DashboardComponent} from './dashboard/dashboard.component';
import {AppellationListComponent} from './appellations/appellation-list/appellation-list.component';
import {LoginComponent} from './core/login/login.component';

const routes: Routes = [
    {path: 'dashboard', component: DashboardComponent},
    {path: 'appellations', component: AppellationListComponent},
    {path: '', component: LoginComponent}
];

@NgModule({
  exports: [RouterModule],
    imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
