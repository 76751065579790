import { Injectable } from '@angular/core';
import {HttpBase} from '../../shared/http-base';
import {Agent} from '../../agents/shared/agent';
import {HttpClient} from '@angular/common/http';
import {ExceptionService} from '../../core/exception.service';
import {Agency} from './agency';

@Injectable({
  providedIn: 'root'
})
export class AgencyService extends HttpBase<Agency> {

    constructor(
        http: HttpClient,
        exception: ExceptionService
    ) {
        super(http, exception, '/agencies', false);
    }

}
