import {Component, OnInit} from '@angular/core';
import {MenuService} from '../shared/menu.service';
import {TableService} from '../../shared/table.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Menu} from '../shared/menu';
import {AppellationUpdateComponent} from '../../appellations/appellation-update/appellation-update.component';
import {Appellation} from '../../appellations/shared/appellation';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MenusAddComponent} from '../menus-add/menus-add.component';
import {MatTableDataSource, MatDialog} from '@angular/material';
import {appSettings} from '../../../settings';
import {AuthService} from '../../core/auth.service';
import {MenusUpdateComponent} from '../menus-update/menus-update.component';

@Component({
    selector: 'app-menus-list',
    templateUrl: './menus-list.component.html',
    styleUrls: ['./menus-list.component.css']
})
export class MenusListComponent implements OnInit {

    menus;

    displayedColumns: string[] = ['name', 'chain', 'actions'];

    type: string;
    chain: string;
    pageTitle: string;

    constructor(
        private dialog: MatDialog,
        private menuService: MenuService,
        private tableService: TableService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private router: Router,
        private auth: AuthService
    ) {
    }

    ngOnInit() {
        /*
        since we're using the same component for both liquor and wine, if the url changes, angular will not reload this so we'll have to
        listen to changes to the url and reload the component accordingly
         */
        this.route.paramMap.subscribe(params => {

            this.type = params.get('type');
            this.chain = params.get('chain');

            this.query();
            switch (this.type) {
                case 'wine':
                    this.pageTitle = 'Wine Lists';
                    break;
                case 'liquor':
                    this.pageTitle = 'Liquor & Beer Lists';
                    break;
            }

        });



    }

    query() {
        this.menuService.query({type: this.type, chain: this.chain}).subscribe(menus => {
            // if a type is assigned, use it to filter the list that will be sent to the view

            this.menus = new MatTableDataSource<Menu>(menus.filter(menu => {
                return menu.type === this.type;
            }));
        });
    }

    copyMenu(menu) {
        this.menuService.copy(menu.id).subscribe(response => {
            const data = this.menus.data;
            data.push(response);
            data.sort(function (a, b) {
                return a.name < b.name ? -1 : 1;
            });
            this.menus.data = data;


            // this.filteredList = this.tableService.filterSort(this.table);
        });
    }

    delete(menu) {
        if (!confirm('Are you sure you want to delete this list? This can\'t be undone.')) {
            return;
        }
        this.menuService.delete(menu.id).subscribe(response => {
            let data = this.menus.data;
            data.splice(data.indexOf(menu), 1);
            this.menus.data = data;
        });
    }

    add() {
        const dialogRef = this.dialog.open(MenusAddComponent, {
            data: {type: this.type, chain: this.chain}
        });

        dialogRef.afterClosed().subscribe(newMenu => {
            if (newMenu) {
                const data = this.menus.data;
                data.push(newMenu);
                data.sort(function (a, b) {
                    return a.name < b.name ? -1 : 1;
                });
                this.menus.data = data;
            }
        });


        const modalRef = this.modalService.open(MenusAddComponent);
        let menu = new Menu();
        menu.type = this.type;
        modalRef.componentInstance.menu = menu;
        modalRef.result.then(result => {
            if (result.id) {
                this.router.navigate(['/menus/edit/', result.id]);
            }
        }, function (error) {

        });
    }


    pdf(menu) {
        let url =  appSettings.api + '/menus/pdf/' + menu.id + '?token=' + this.auth.getToken();
        window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    excel(menu) {
        let url = appSettings.api + '/menus/excel/' + menu.id + '?token=' + this.auth.getToken();
        window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    applyFilter(filterValue: string) {
        this.menus.filter = filterValue.trim().toLowerCase();

    }

}
