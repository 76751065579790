import {Component, OnInit, ViewChild} from '@angular/core';

import {ProductService} from './shared/product.service';
import {Product} from './shared/product';
import {AppRoutingModule} from '../app-routing.module';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TableService} from '../shared/table.service';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {switchMap} from 'rxjs/operators';
import {ProgressService} from '../core/progress/progress.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

    products;
    displayedColumns: string[] = ['producer', 'name', 'fname', 'appellation', 'actions'];
    page = {title: null, type: null};
    query = {type: '', chain: ''};

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private productService: ProductService,
        private route: ActivatedRoute,
        private router: Router,
        public filter: TableService,
        private progress: ProgressService,
        private _snackBar: MatSnackBar


    ) {}

    ngOnInit() {

        this.route.params.subscribe(params => {
            this.query.type = params['type'];
            this.getProducts();

            switch (params['type']) {
                case 'wine':
                    this.displayedColumns = ['producer', 'name', 'fname', 'appellation', 'actions'];
                    this.page.title = 'Wine';
                    this.page.type = 'wine';
                    break;
                case 'liquor':
                    this.displayedColumns = ['name', 'actions'];
                    this.page.title = 'Liquor & Beer';
                    this.page.type = 'liquor';
                    break;
            }
        });


    }

    getProducts() {
        this.progress.show();
        this.query.chain = this.filter.productList.chain;
        this.productService.query(this.query)
            .subscribe(products => {
                this.progress.hide();
                this.products = new MatTableDataSource<Product>(products);
                this.products.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case 'producer.name':
                            if (item.producer) {
                                return item.producer.name;
                            }
                            break;
                        case 'appellation.name':
                            if (item.appellation) {
                                return item.appellation.name;
                            }
                            break;
                        default: return item[property];
                    }
                };
                this.products.sort = this.sort;
                this.products.paginator = this.paginator;

                this.products.filterPredicate = (data, filter: string)  => {
                    const accumulator = (currentTerm, key) => {
                        if (key === 'appellation' && data.appellation) {
                            return currentTerm + data.appellation.name;
                        }
                        if (key === 'producer' && data.producer) {
                            return currentTerm + data.producer.name;
                        }
                        return currentTerm + data[key];
                    };
                    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                    // Transform the filter by converting it to lowercase and removing whitespace.
                    const transformedFilter = filter.trim().toLowerCase();
                    return dataStr.indexOf(transformedFilter) !== -1;
                };

                this.applyFilter(this.filter.productList.filter);
            });
    }

    delete(element) {
      if (!confirm('This product will be deleted permanently, are you sure you want to continue?')) {
        return;
      }
        this.productService.delete(element.id).subscribe(response => {
          let data = this.products.data;
          data.splice(data.indexOf(element), 1);
          this.products.data = data;
        }, error => {
          this._snackBar.open(error.error.message,'',  {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        });
    }

    applyFilter(filterValue: string) {
        this.products.filter = filterValue.trim().toLowerCase();
    }

}
