import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';

import {ProducersListComponent} from './producers-list/producers-list.component';
import {ProducersRoutingModule} from './producers-routing.module';
import { ProducerUpdateComponent } from './producer-update/producer-update.component';

@NgModule({
  imports: [
    SharedModule,
      CommonModule,
      ProducersRoutingModule
  ],
  declarations: [ProducersListComponent, ProducerUpdateComponent],
  entryComponents: [ProducerUpdateComponent]
})
export class ProducersModule { }
