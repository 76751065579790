import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MenuItem} from '../shared/menu-item';
import {MenuItemService} from '../shared/menu-item.service';

@Component({
    selector: 'app-menu-item-edit',
    templateUrl: './menu-item-edit.component.html',
    styleUrls: ['./menu-item-edit.component.css']
})
export class MenuItemEditComponent implements OnInit {

    form: FormGroup;
    item: MenuItem;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<MenuItemEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private menuItemService: MenuItemService
    ) {
        this.form = this.fb.group({
            id: [''],
            gp_override: [''],
            notes: ['']
        });
    }


    ngOnInit() {
        this.item = this.data.item;

        this.form.patchValue({
            id: this.item.id,
            gp_override: this.item.gp_override,
            notes: this.item.notes
        });
    }

    save() {
        let menuItem = this.form.value;

        this.menuItemService.update(menuItem).subscribe(response => {
            if (response) {
                this.dialogRef.close(response);
            }
        });
    }

}
