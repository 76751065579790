import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ProducersListComponent} from './producers-list/producers-list.component';

const producersRoutes: Routes = [
    { path: 'producers',  component: ProducersListComponent },
    // { path: 'products/:id', component: ProductsUpdateComponent }
];

@NgModule({
  imports: [
      RouterModule.forChild(producersRoutes)
  ],
    exports: [
        RouterModule
    ],
  declarations: []
})
export class ProducersRoutingModule { }
