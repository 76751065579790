import {Component, Input, OnInit} from '@angular/core';
import {Menu} from '../shared/menu';
import {Glasspour} from '../shared/glasspour';
import {GlasspourService} from '../shared/glasspour.service';

@Component({
    selector: 'app-glasspour',
    templateUrl: './glasspour.component.html',
    styleUrls: ['./glasspour.component.scss']
})
export class GlasspourComponent implements OnInit {

    @Input() menu: Menu;
    @Input() refresh;

    public glass1map;
    public glass2map;
    public glass1;
    public glass2;
    public categoryMap;
    public glasspours;
    public priceMap;

    constructor(
        private glasspourService: GlasspourService
    ) {
    }

    ngOnInit() {

        this.priceMap = {};

        this.glasspourService.query().subscribe(response => {
            for (let price of response) {
                if (!this.priceMap[price.country]) {
                    this.priceMap[price.country] = {};
                }
                this.priceMap[price.country][price.bottle] = {big: price.big, small: price.small};

            }

        });

        if (this.refresh) {
            // listen to our parent to see if we need to update the glass pour list
            this.refresh.subscribe(event => {
                //console.log('update gp');
                this.glassPour();
            });
        }

        this.glassPour();


    }

    category2(parent, category) {
        for (let child of category.children) {
            this.categoryMap[child.id] = parent.id;
            this.category2(parent, child);
        }

        for (let item of category.menu_items) {
            this.glass1map[this.categoryMap[item.category_id]].listed.push(item);
            this.glass2map[this.categoryMap[item.category_id]].listed.push(item);

        }
    }

    glassPour() {
        this.glass1map = {};
        this.glass2map = {};
        this.categoryMap = {};

        // setup the glass pour categories. we're only using top level categories
        for (let category of this.menu.categories) {
            this.glass1map[category.id] = {category: category, listed: [], tap: []};
            this.glass2map[category.id] = {category: category, listed: [], tap: []};
            this.categoryMap[category.id] = category.id;
        }

        // so now we have to cycle through all categories and sub categories menu items to see if
        for (let category of this.menu.categories) {
            this.category2(category, category);
        }

        this.glass1 = Object.values(this.glass1map);
        this.glass2 = Object.values(this.glass2map);

    }

}
