import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AgencyService} from '../shared/agency.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Agent} from '../../agents/shared/agent';
import {ProgressService} from '../../core/progress/progress.service';
import {Agency} from '../shared/agency';

@Component({
    selector: 'app-update',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

    form: FormGroup;
    action: string;

    constructor(
        public fb: FormBuilder,
        public agenciesService: AgencyService,
        public dialogRef: MatDialogRef<UpdateComponent>,
        @Inject(MAT_DIALOG_DATA) public agency: Agency,
        public progress: ProgressService,

    ) {
        this.form = this.fb.group({
            name: [''],
            id: ['']
        });
    }

    ngOnInit() {

        this.action = this.agency.id ? 'Update' : 'Add';

        if (this.agency.id) {
            this.form.patchValue({
                name: this.agency.name,
                id: this.agency.id
            });
        }
    }

    save() {
        let agency = this.form.value;
        if (this.form.invalid) {
            return;
        }
        this.progress.show();
        this.agenciesService.update(agency).subscribe(response => {
            this.progress.hide();
            this.dialogRef.close(response);
        });
    }

}
