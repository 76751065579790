import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProducerService} from '../shared/producer.service';
import {Producer} from '../shared/producer';
import {TableService} from '../../shared/table.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProducerUpdateComponent} from '../producer-update/producer-update.component';
import {AppellationUpdateComponent} from '../../appellations/appellation-update/appellation-update.component';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Appellation} from '../../appellations/shared/appellation';
import {ProgressService} from '../../core/progress/progress.service';

@Component({
    selector: 'app-producers-list',
    templateUrl: './producers-list.component.html',
    styleUrls: ['./producers-list.component.css']
})
export class ProducersListComponent implements OnInit, OnDestroy {

    producers;
    displayedColumns: string[] = ['name', 'actions'];
    tableFilter: string;

    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private producerService: ProducerService,
        private dialog: MatDialog,
        private progress: ProgressService,
        private table: TableService
    ) {
    }

    ngOnInit() {
        this.tableFilter = this.table.producersList.filter;
        this.getProducers();
    }

    getProducers() {
        this.progress.show();
        this.producerService.query()
            .subscribe(producers => {
                this.producers = producers;
                this.producers = new MatTableDataSource<Producer>(producers);
                this.producers.sort = this.sort;
                this.progress.hide();
                this.applyFilter(this.tableFilter);
            });
    }

    applyFilter(filterValue: string) {
        if (filterValue) {
            this.producers.filter = filterValue.trim().toLowerCase();
        }
    }

    add() {
        let producer = new Producer();
        producer.representations = [];
        const dialogRef = this.dialog.open(ProducerUpdateComponent, {
            data: producer,
            width: '600px',
        });

        dialogRef.afterClosed().subscribe(producer => {
            if (producer && producer.id) {
                let data = this.producers.data;
                data.push(producer);
                this.producers.data = data;

            }
        });
    }

    edit(producer) {
        const dialogRef = this.dialog.open(ProducerUpdateComponent, {
            data: Object.assign({}, producer),
            width: '600px'
        });

        dialogRef.afterClosed().subscribe(newProducer => {
            if (newProducer) {
                const data = this.producers.data;
                data.splice(this.producers.data.indexOf(producer), 1);
                data.push(newProducer);
                this.producers.data = data;
            }
        });
    }

    ngOnDestroy(): void {
        this.table.producersList.filter = this.tableFilter;
    }

}
