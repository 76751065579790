import {Component, Input, OnInit} from '@angular/core';
import {AppellationUpdateComponent} from '../../appellations/appellation-update/appellation-update.component';
import {MatDialog} from '@angular/material';
import {AgentComponent} from '../agent/agent.component';

@Component({
  selector: 'app-category-view',
  templateUrl: './category-view.component.html',
  styleUrls: ['./category-view.component.css']
})
export class CategoryViewComponent implements OnInit {

    @Input() categories;
    @Input() level;
    @Input() parentCategory;
    @Input() reorder;
    @Input() menu;

    indent = {};
    className = '';
    headerClass = {};
    containerClass = {};

    constructor(
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.level ++;
        this.indent['margin-left'] =  this.level * 10 + 'px';

        this.className = 'level-' + this.level;

        this.headerClass['h4'] = this.level === 1;
        this.headerClass['h5'] = this.level > 1;

        this.containerClass['card'] = this.level === 1;
        this.containerClass['bg-light'] = this.level === 1;
        this.containerClass['mb-3'] = this.level === 1;
        this.containerClass['level-' + this.level] = true;

    }

    viewAgent(product) {
        const dialogRef = this.dialog.open(AgentComponent, {
            data: {
                menu: this.menu,
                product: product
            }
        });

        /*
        dialogRef.afterClosed().subscribe(newAppellation => {
            if (newAppellation) {
                const data = this.appellations.data;
                data.splice(this.appellations.data.indexOf(appellation), 1);
                data.push(newAppellation);
                this.appellations.data = data;
            }
        });
        */
    }

}
