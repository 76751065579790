import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ProducerService} from '../../producers/shared/producer.service';
import {Producer} from '../../producers/shared/producer';

@Component({
    selector: 'app-agent',
    templateUrl: './agent.component.html',
    styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {

    public producer: Producer;

    constructor(
        public dialogRef: MatDialogRef<AgentComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        public producerService: ProducerService

    ) {
    }

    ngOnInit() {
        this.producerService.get(this.data.product.producer_id).subscribe(response => {
            this.producer  = response;
        });


    }

}
