import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {Subscription} from 'rxjs';
import {AppellationUpdateComponent} from '../../appellations/appellation-update/appellation-update.component';
import {Appellation} from '../../appellations/shared/appellation';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MenuItemAddComponent} from '../menu-item-add/menu-item-add.component';
import {Product} from '../../products/shared/product';
import {MenuItemService} from '../shared/menu-item.service';
import {Category} from '../shared/category';
import {CategoryService} from '../shared/category.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatDialog} from '@angular/material';
import {MenuItemEditComponent} from '../menu-item-edit/menu-item-edit.component';

@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

    @Input() categories;
    @Input() level;
    @Input() parentCategory;
    @Input() menu;

    @Output() update = new EventEmitter<string>();

    indent = {};
    className = '';
    headerClass = {};
    containerClass = {};

    constructor(
        private menuItemService: MenuItemService,
        private modalService: NgbModal,
        private categoryService: CategoryService,
        private dialog: MatDialog
                ) {
    }

    ngOnInit() {
        this.level ++;
        this.indent['margin-left'] =  this.level * 10 + 'px';


        this.className = 'level-' + this.level;

        this.headerClass['h4'] = this.level === 1;
        this.headerClass['h5'] = this.level > 1;

        this.containerClass['card'] = this.level === 1;
        this.containerClass['bg-light'] = this.level === 1;
        this.containerClass['mb-3'] = this.level === 1;
        this.containerClass['level-' + this.level] = true;

    }

    addMenuItem(category) {
        const dialogRef = this.dialog.open(MenuItemAddComponent, {
            data: {
                menu: this.menu,
                category: category
            },
            maxWidth: 600
        });

        dialogRef.afterClosed().subscribe(menuItem => {
            if (menuItem) {
                category.menu_items.push(menuItem);
                this.sort(category);
                this.update.emit();
            }
        });

    }

    deleteMenuItem(item, category) {
        this.menuItemService.delete(item.id).subscribe(response => {
            category.menu_items.splice(category.menu_items.indexOf(item), 1);
            this.update.emit();
        });
    }

    addCategory(category) {
        let newCategory = new Category();
        newCategory.menu_id = category.menu_id;
        newCategory.parent_id = category.id;
        newCategory.name = 'New Category';
        newCategory.edit = true;
        newCategory.menu_items = [];
        newCategory.weight = category.children.length;
        category.children.push(newCategory);
    }

    updateCategory(category) {
        category.edit = false;
        this.categoryService.update(category).subscribe(response => {
            category.id = response.id;
        });
    }

    deleteCategory(category) {
        if (!window.confirm('Are you sure you want to delete this category? All associated products will also be removed.')) {
            return;
        }
        if (this.parentCategory.id === 0) {
            this.menu.categories.splice(this.menu.categories.indexOf(category), 1);
        } else {
            this.parentCategory.children.splice(this.parentCategory.children.indexOf(category), 1);
        }
        this.categoryService.delete(category.id).subscribe();
    }

    stuff(value) {
        this.update.emit(value);
    }

    drop(event: CdkDragDrop<string[]>, category) {
        moveItemInArray(category.menu_items, event.previousIndex, event.currentIndex);
        let sort = 0;
        let save = [];
        category.menu_items.forEach(item => {
            if (item.active) {
                item.sort = sort;
                save.push({id: item.id, sort: sort});
                sort ++;
            }
        });
        this.menuItemService.sort(save).subscribe(response => {

        });

    }

    sort(category) {
        category.menu_items.sort(function (a, b) {
            return a.sort - b.sort;

        });
    }

    saveOrder(categories) {
        let weight = 0;
        let save = [];
        categories.forEach(category => {
            category.weight = weight;
            save.push({weight: weight, id: category.id});
            weight ++;
        });

        this.categoryService.sort(save).subscribe(response => {
            console.log(response);
        });
        this.update.emit();
    }

    updateItem(item, category) {
        if (item.product.type === 'wine') {
            item.sort = item.price;
        }
        this.sort(category);
        this.menuItemService.update(item).subscribe(response => {
            this.update.emit();
            //console.log(response);
        });
    }

    editMenuItem(item, category) {
        const dialogRef = this.dialog.open(MenuItemEditComponent, {
            data: {
                item: item,
                category: category
            },
            maxWidth: 600
        });

        dialogRef.afterClosed().subscribe(menuItem => {
            if(menuItem) {
                item.gp_override = menuItem.gp_override;
                item.notes = menuItem.notes;
            }
        });

    }
}
