import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TableService} from './table.service';
import {
    MatAutocompleteModule,
    MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatDividerModule, MatFormFieldModule,
    MatIconModule, MatInputModule,
    MatListModule,
    MatMenuModule, MatPaginatorModule,
    MatSidenavModule, MatSortModule,
    MatTableModule,
    MatToolbarModule, MatDialogRef, MatProgressBarModule
} from '@angular/material';
import {LayoutModule} from '@angular/cdk/layout';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {UcWidgetModule} from 'ngx-uploadcare-widget';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatSortModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDividerModule,
        MatPaginatorModule,
        MatMenuModule,
        DragDropModule,
        MatButtonToggleModule,
        MatProgressBarModule,
        UcWidgetModule,
        MatSnackBarModule

    ],
    imports: [
        CommonModule,
        FormsModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatSortModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatCardModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDividerModule,
        MatPaginatorModule,
        MatMenuModule,
        DragDropModule,
        MatButtonToggleModule,
        MatProgressBarModule,
        UcWidgetModule
    ],
    declarations: [

        ],
    providers: [
        TableService,
        { provide: MatDialogRef, useValue: {} },
    ]
})
export class SharedModule {
    //
    static forRoot() {
        return {
            ngModule: SharedModule,
        };
    }
}
