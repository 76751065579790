import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexComponent} from './index/index.component';
import {SharedModule} from '../shared/shared.module';
import {AgenciesRoutingModule} from './agencies-routing.module';
import { UpdateComponent } from './update/update.component';

@NgModule({
    imports: [
        CommonModule,
        AgenciesRoutingModule,
        SharedModule,
    ],
    declarations: [IndexComponent, UpdateComponent],
    entryComponents: [UpdateComponent]
})
export class AgenciesModule {
}
