import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexComponent} from './index/index.component';
import {AgentsRoutingModule} from './agents-routing.module';
import {SharedModule} from '../shared/shared.module';
import {ViewComponent} from './view/view.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UpdateComponent} from './update/update.component';

@NgModule({
    imports: [
        CommonModule,
        AgentsRoutingModule,
        SharedModule,
        NgbModule
    ],
    declarations: [IndexComponent, ViewComponent, UpdateComponent],
    entryComponents: [ViewComponent, UpdateComponent]
})
export class AgentsModule {
}
