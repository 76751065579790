import {Component, OnInit, Input, Inject} from '@angular/core';
import {AppellationService} from '../shared/appellation.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Appellation} from '../shared/appellation';
import {ProgressService} from '../../core/progress/progress.service';


@Component({
    selector: 'app-appellation-update',
    templateUrl: './appellation-update.component.html',
    styleUrls: ['./appellation-update.component.css']
})
export class AppellationUpdateComponent implements OnInit {

    public action: string;

    constructor(
        public dialogRef: MatDialogRef<AppellationUpdateComponent>,
        @Inject(MAT_DIALOG_DATA) public appellation: Appellation,
        public appellationService: AppellationService,
        public think: ProgressService
    ) {
    }

    ngOnInit() {
        this.action = this.appellation.id ? 'Edit' : 'Add';
    }

    saveAppellation() {
        this.think.show();
        this.appellationService.update(this.appellation)
            .subscribe((response) => {
                this.think.hide();
                if (response) {
                    this.dialogRef.close(response);
                }
            });
    }

}
