import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ExceptionService} from '../../core/exception.service';
import {HttpBase} from '../../shared/http-base';
import {MenuItem} from './menu-item';

@Injectable({
  providedIn: 'root'
})
export class MenuItemService extends  HttpBase<MenuItem> {

    constructor(
        http: HttpClient,
        exception: ExceptionService
    ) {
        super(http, exception, '/menu-items', false);
    }

    sort(sort) {
        return this.http.post(this.url + '/sort', sort);
    }

}
