import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  thinking: boolean;

  private countdownEndSource = new Subject<boolean> ();
  public countdownEnd$ = this.countdownEndSource.asObservable();


  constructor() { }



  public show() {
    this.thinking = true;
   this.countdownEndSource.next(this.thinking);

  }

  public hide() {
    this.thinking = false;
    this.countdownEndSource.next(this.thinking);
  }

  public isShowing() {
    return this.thinking;
  }

}
