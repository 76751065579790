import {appSettings} from '../../settings';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ExceptionService} from '../core/exception.service';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {empty} from 'rxjs/internal/Observer';

export abstract class HttpBase<T extends { id: number; }> {

    protected url = appSettings.api;
    protected collection: any[];
    protected hashMap: {};

    constructor(
        protected http: HttpClient,
        private error: ExceptionService,
        private name: string,
        private save: boolean
    ) {
        this.url += name;
        this.hashMap = {};
        this.collection = [];
    }

    query(params?: any): Observable<T[]> {


        if (this.collection && this.collection.length > 0) {
            return of(this.collection);
        }

        let httpParams = new HttpParams();

        if (!params) {
            params = {};
        }

        for (let param in params) {
            if (params.hasOwnProperty(param) && params[param]) {
                httpParams = httpParams.set(param, params[param]);
            }
        }

        return this.http.get<T[]>(this.url, {params: httpParams})
            .pipe(
                tap(response => {
                    if (this.save) {
                        this.collection = response;
                        for (let item of this.collection) {
                            this.hashMap[item.id] = item;
                        }
                    }

                    // this.sort();
                }),
                catchError(err => of([]))
            );
    }

    get(id: number): Observable<T> {
        return this.http.get<T>(this.url + '/' + id);
    }


    update(model: T, save: boolean = true): Observable<T> {
        if (model.id) {
            return this.http.put<T>(this.url + '/' + model.id, model)
                .pipe();
        } else {
            return this.http.post<T>(this.url, model)
                .pipe(
                    tap(response => {
                        if (this.save && save) {
                            this.collection.push(response);
                            this.hashMap[response.id] = response;
                        }
                    })
                );
        }
    }

    delete(id: number): Observable<T> {
        return this.http.delete<T>(this.url + '/' + id).pipe(
            tap(response => {
                if (this.collection.indexOf(this.hashMap[id])) {
                    this.collection.splice(this.collection.indexOf(this.hashMap[id]), 1);
                }
            })
        );
    }

    autocomplete(params?: Object): Observable<T[]> {

        let httpParams = new HttpParams();

        if (!params) {
            params = {};
        }

        for (let param in params) {
            if (params.hasOwnProperty(param) && params[param]) {
                httpParams = httpParams.set(param, params[param]);
            }
        }

        return this.http.get<T[]>(this.url, {params: httpParams});
    }

}
