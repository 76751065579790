import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Producer} from '../../producers/shared/producer';
import {ProgressService} from '../../core/progress/progress.service';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Agent} from '../shared/agent';
import {AgentsService} from '../shared/agents.service';
import {RegionsService} from '../../shared/regions.service';
import {AgencyService} from '../../agencies/shared/agency.service';
import {Region} from '../../shared/region';
import {Agency} from '../../agencies/shared/agency';
import {ProducerService} from '../../producers/shared/producer.service';
import {debounceTime, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-update',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

    form: FormGroup;
    action: string;
    regions: Region[];
    agencies: Agency[];
    filteredProducers: Observable<Producer[]>;

    constructor(
        public agentService: AgentsService,
        public dialogRef: MatDialogRef<UpdateComponent>,
        @Inject(MAT_DIALOG_DATA) public agent: Agent,
        public progress: ProgressService,
        public fb: FormBuilder,
        public regionService: RegionsService,
        public agencyService: AgencyService,
        public producerService: ProducerService
    ) {

        this.form = this.fb.group({
            fname: [''],
            lname: [''],
            phone: [''],
            email: [''],
            region_id: [''],
            agency_id: [''],
            producerSearch: [''],
            id: [''],
            newAgency: [''],
            representations: this.fb.array([
            ])
        });


        this.filteredProducers = this.form
            .get('producerSearch')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => this.producerService.autocomplete({query: value}))
            );

    }

    ngOnInit() {

        this.action = this.agent.id ? 'Update' : 'Add';

        this.regionService.query().subscribe(response => {
            this.regions = response;
        });

        this.agencyService.query().subscribe(response => {
            let agencies = [...response];
            agencies.sort(function (a, b) {
                if (a.name === b.name) {
                    return 0;
                }
                return a.name < b.name ? -1 : 1;
            });

            agencies.push({id: -1, name: '-- New Agency --'});

            this.agencies = agencies;
        });

        if (this.agent.id) {
            this.form.patchValue({
                fname: this.agent.fname,
                lname: this.agent.lname,
                phone: this.agent.phone,
                email: this.agent.email,
                id: this.agent.id,
                region_id: this.agent.region_id,
                agency_id: this.agent.agency_id
            });
            for (let rep of this.agent.representations) {
                (<FormArray>this.form.get('representations')).push(this.fb.group({
                    id: [rep.id],
                    producer: [rep.producer.name],
                    producer_id: [rep.producer.id],
                    active: [true]
                }));
            }
        }

    }

    addAgency() {
        let agency = new Agency();
        agency.name = this.form.get('newAgency').value;
        this.agencyService.update(agency).subscribe(response => {
            this.agencies.push(response);
            this.form.get('agency_id').setValue(response.id);
        });

    }

    save() {
        if (this.form.invalid) {
            return;
        }
        let agent = this.form.value;
        this.progress.show();
        this.agentService.update(agent).subscribe(response => {
            this.progress.hide();
            if (response) {
                this.dialogRef.close(response);
            }
        });
    }

    displayFn(user?): string | undefined {
        return user ? user.name : undefined;
    }

    selectProducer(event) {
        if (event && event.option && event.option.value) {
            let producer = event.option.value;
            (<FormArray>this.form.get('representations')).push(this.fb.group({
                id: [null],
                producer: [producer.name],
                active: [true],
                producer_id: [producer.id]
            }));
            this.form.get('producerSearch').setValue('');
        }
    }

}
