import {Component, Inject, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RegionsService} from '../../shared/regions.service';
import {AgentsService} from '../shared/agents.service';
import {ProducerService} from '../../producers/shared/producer.service';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Agent} from '../shared/agent';

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ViewComponent>,
        @Inject(MAT_DIALOG_DATA) public agent: Agent,
    ) {
    }

    ngOnInit() {

    }

}
