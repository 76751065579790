import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ExceptionService} from '../../core/exception.service';
import {HttpBase} from '../../shared/http-base';
import {Category} from './category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends HttpBase<Category> {

    constructor(
        http: HttpClient,
        exception: ExceptionService
    ) {
        super(http, exception, '/categories', false);
    }

    sort(sort) {
        return this.http.post(this.url + '/sort', sort);
    }
}
