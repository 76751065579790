import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        const authReq = req.clone({
            setHeaders: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });

        return next.handle(authReq);
    }
}
