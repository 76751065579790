import {Component, Inject, Input, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {ProducerService} from '../../producers/shared/producer.service';
import {AppellationService} from '../../appellations/shared/appellation.service';
import {Product} from '../../products/shared/product';
import {Producer} from '../../producers/shared/producer';
import {Appellation} from '../../appellations/shared/appellation';
import {Menu} from '../shared/menu';
import {ProductService} from '../../products/shared/product.service';
import {MenuItemService} from '../shared/menu-item.service';
import {MenuItem} from '../shared/menu-item';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-menu-item-add',
    templateUrl: './menu-item-add.component.html',
    styleUrls: ['./menu-item-add.component.css']
})
export class MenuItemAddComponent implements OnInit {

    producers: Array<Producer>;
    producer: Producer;
    appellations: Array<Appellation>;

    filteredProducers: Observable<Producer[]>;
    filteredProducts: Observable<Product[]>;
    filteredAppellations: Observable<Appellation[]>;

    newProduct = false;
    products: Array<Product>;

    menu;
    category;

    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<MenuItemAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private producerService: ProducerService,
        private appellationService: AppellationService,
        private productService: ProductService,
        private menuItemService: MenuItemService,
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            type: [''],
            name: [''],
            producer: [''],
            appellation: [''],
            description: [''],
            fname: [''],
            tap: [0],
            glass: [0],
            short_name: [''],
            chain: [''],
            cost: [''],
            price: [''],
            product_id: [-1],
            category_id: [''],
            menu_id: [''],
            active: [1],
            '1oz': [''],
            '2oz': [''],
            '3oz': [''],
            '6oz': [''],
            '32oz': [''],

        });

        this.filteredProducers = this.form
            .get('producer')
            .valueChanges
            .pipe(
                debounceTime(300),
                tap(() => {
                    this.producer = null;
                }),
                switchMap(value => this.producerService.autocomplete({query: value}))
            );

        this.filteredAppellations = this.form
            .get('appellation')
            .valueChanges
            .pipe(
                debounceTime(300),
                tap(() => {
                    this.producer = null;
                }),
                switchMap(value => this.appellationService.autocomplete({query: value}))
            );

        this.filteredProducts = this.form
            .get('name')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => this.productService.autocomplete({query: value}))
            );


    }

    ngOnInit() {
        this.category = this.data.category;
        this.menu = this.data.menu;

        this.form.patchValue({
            category_id: this.category.id,
            menu_id: this.category.menu_id,
            type: this.menu.type,
            chain: this.menu.chain,
        });

        if (this.menu.type === 'liquor') {
            this.productService.query({type: 'liquor'}).subscribe(products => {
                let sortedProducts = products.sort(function (a, b) {
                    if (a.name === b.name) {
                        return 0;
                    }
                    return a.name < b.name ? -1 : 1;
                });
                this.products = sortedProducts;
                let newProductOption = new Product();
                newProductOption.name = '-- New Product --';
                this.products.push(newProductOption);
            });
        }
    }


    selectProducer(event) {
        this.productService.query({producer_id: event.option.value.id}).subscribe(products => {
            this.products = products;
            let newProductOption = new Product();
            newProductOption.name = '-- New Product --';
            this.products.push(newProductOption);
        });

    }

    selectProduct() {

        if (!this.form.controls.product_id.value) {
            this.newProduct = true;
        }
    }

    saveProducer() {
        let name = this.form.get('producer').value;
        let newProducer = new Producer();
        newProducer.name = name;
        this.producerService.update(newProducer, false).subscribe(response => {
            this.form.controls['producer'].setValue(response);
            let newProductOption = new Product();
            newProductOption.name = '-- New Product --';
            newProductOption.id = -1;
            this.products = [newProductOption];

            this.newProduct = true;
        });
    }

    saveAppellation() {
        let name = this.form.get('appellation').value;
        let newAppellation = new Appellation();
        newAppellation.name = name;
        this.appellationService.update(newAppellation, false).subscribe(response => {
            this.form.controls['appellation'].setValue(response);
            // this.form.setValue({appellation: response});
        });
    }


    save() {
        let menuItem = this.form.value;


        if (menuItem.producer) {
            menuItem.producer_id = menuItem.producer.id;
        }

        if (menuItem.appellation) {
            menuItem.appellation_id = menuItem.appellation.id;
        }

        this.menuItemService.update(menuItem).subscribe(response => {
            if (response) {
                this.dialogRef.close(response);
            }
        });
    }

    displayFn(user?): string | undefined {
        return user ? user.name : undefined;
    }


}
