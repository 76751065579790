import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {LoggerService} from './logger.service';
import {AppellationsModule} from '../appellations/appellations.module';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {NavComponent} from './nav/nav.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HeadersInterceptor} from './headers-interceptor';
import {ProductsModule} from '../products/products.module';
import {ProducersModule} from '../producers/producers.module';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {MenusModule} from '../menus/menus.module';
import {TreeModule} from 'angular-tree-component';
import {AgentsModule} from '../agents/agents.module';
import {MatToolbarModule} from '@angular/material';
import {AuthService} from './auth.service';
import {LoginComponent} from './login/login.component';
import {AuthInterceptor} from './auth.interceptor';
import { ProgressComponent } from './progress/progress.component';
import {AgenciesModule} from '../agencies/agencies.module';

@NgModule({
    exports: [
        NavComponent,
        DashboardComponent,
        LoginComponent
    ],
    imports: [
        AppellationsModule,
        ProductsModule,
        ProducersModule,
        MenusModule,
        AgentsModule,
        CommonModule,
        NgbModule,
        RouterModule,
        HttpClientModule,
        SharedModule,
        AgenciesModule
    ],
    providers: [
        AuthService,
        LoggerService,
        {provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
    ],
    declarations: [
        DashboardComponent,
        NavComponent,
        LoginComponent,
        ProgressComponent
    ]
})
export class CoreModule {
    static forRoot() {
        return {
            ngModule: CoreModule,
        };
    }
}
