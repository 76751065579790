import {MenuItem} from './menu-item';

export class Category {

    name: string;
    parent_id: number;
    children: Array<Category>;
    edit: boolean;
    id:  number;
    menu_id: number;
    menu_items: Array<MenuItem>;
    weight: number;
}
