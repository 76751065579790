import { Injectable } from '@angular/core';
import {appSettings} from '../../../settings';
import {HttpClient} from '@angular/common/http';
import {ExceptionService} from '../../core/exception.service';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Producer} from './producer';
import {HttpBase} from '../../shared/http-base';

@Injectable({
  providedIn: 'root'
})
export class ProducerService extends HttpBase<Producer> {

    constructor(
        http: HttpClient,
        exception: ExceptionService
    ) {
        super(http, exception, '/producers', false);
    }


}
