import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from './user';
import {appSettings} from '../../settings';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    protected url = appSettings.api;

    constructor(
        private router: Router,
        private http: HttpClient) {
    }

    login(username: string, password: string) {
        return this.http.post<User>(this.url + '/users/login', {username, password})
            .pipe(
                tap(res => {
                    this.setSession(res);
                }));
        // this is just the HTTP call,
        // we still need to handle the reception of the token
    }

    private setSession(authResult) {
        localStorage.setItem('id_token', authResult.token);
        this.router.navigate(['/dashboard']);
    }

    logout() {
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
    }

    public isLoggedIn() {
        return localStorage.getItem('id_token');
        // return moment().isBefore(this.getExpiration());
    }

    public getToken() {
        return localStorage.getItem('id_token');
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    getExpiration() {
        const expiration = localStorage.getItem('expires_at');
        const expiresAt = JSON.parse(expiration);
    }
}
