import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ProductsComponent} from './products.component';
import {ProductsUpdateComponent} from './products-update/products-update.component';

const productsRoutes: Routes = [
    {path: 'products/view/:id', component: ProductsUpdateComponent},
    {path: 'products/:type', component: ProductsComponent},
    {path: 'products/edit/:id', component: ProductsUpdateComponent},
    {path: 'products/add/:type', component: ProductsUpdateComponent},
    {path: 'products', component: ProductsComponent}

];

@NgModule({
    imports: [
        RouterModule.forChild(productsRoutes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class ProductsRoutingModule {
}
