import {Injectable} from '@angular/core';
import {HttpBase} from '../../shared/http-base';
import {Menu} from './menu';
import {HttpClient} from '@angular/common/http';
import {ExceptionService} from '../../core/exception.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MenuService extends HttpBase<Menu> {


    constructor(
        http: HttpClient,
        exception: ExceptionService
    ) {
        super(http, exception, '/menus', false);
    }


    copy(id): Observable<Menu> {
        return this.http.get<Menu>(this.url + '/copy/' + id);
    }

    pdf(id): Observable<Menu> {
        return this.http.get<Menu>(this.url + '/pdf/' + id);
    }

}
