import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Appellation} from '../shared/appellation';
import {AppellationService} from '../shared/appellation.service';
import {LoggerService} from '../../core/logger.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppellationUpdateComponent} from '../appellation-update/appellation-update.component';
import {MatDialog, MatSort, MatTable, MatTableDataSource} from '@angular/material';
import {ProgressService} from '../../core/progress/progress.service';
import {TableService} from '../../shared/table.service';

@Component({
    selector: 'app-appellation-list',
    templateUrl: './appellation-list.component.html',
    styleUrls: ['./appellation-list.component.css'],
    entryComponents: [AppellationUpdateComponent]

})
export class AppellationListComponent implements OnInit, OnDestroy {

    appellations;

    displayedColumns: string[] = ['name', 'fname', 'actions'];
    tableFilter: string;

    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private dialog: MatDialog,
        private modalService: NgbModal,
        private appellationService: AppellationService, private logger: LoggerService,
        private progress: ProgressService,
        private table: TableService

    ) {}

    ngOnInit() {
        this.tableFilter = this.table.appellationsList.filter;
        this.getAppellations();
    }

    getAppellations() {
        this.progress.show();
        this.appellationService.query()
            .subscribe(appellations => {
                this.appellations = new MatTableDataSource<Appellation>(appellations);
                this.appellations.sort = this.sort;
                this.progress.hide();
                this.applyFilter(this.tableFilter);
            });
    }

    editAppellation(appellation) {
        const dialogRef = this.dialog.open(AppellationUpdateComponent, {
            data: Object.assign({}, appellation)
        });

        dialogRef.afterClosed().subscribe(newAppellation => {
            if (newAppellation) {
                const data = this.appellations.data;
                data.splice(this.appellations.data.indexOf(appellation), 1);
                data.push(newAppellation);
                this.appellations.data = data;
            }
        });

    }

    applyFilter(filterValue: string) {
        if (filterValue) {
            this.appellations.filter = filterValue.trim().toLowerCase();
        }

    }

    add() {
        const dialogRef = this.dialog.open(AppellationUpdateComponent, {
            data: new Appellation()
        });

        dialogRef.afterClosed().subscribe(appellation => {
            if (appellation && appellation.id) {
                let data = this.appellations.data;
                data.push(appellation);
                this.appellations.data = data;

            }
        });

    }

    ngOnDestroy(): void {
        this.table.appellationsList.filter = this.tableFilter;
    }

}
