import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TableService {

    appellationsList = {filter: '', sort: 'name', direction: 1};
    productList = {chain: '', filter: ''};
    menusList = {filter: '', sort: 'name', direction: 1, filterParams: ['name'], conditions: {}};
    producersList = {filter: '', sort: 'name', direction: 1, filterParams: ['name']};
    agentsList = {filter: '', sort: 'name', direction: 1, filterParams: ['name']};
    agenciesList = {filter: '', sort: 'name', direction: 1, filterParams: ['name']};

    constructor() {
        console.log('new table service');
    }

}
