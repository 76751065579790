import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MenuService} from '../shared/menu.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Menu} from '../shared/menu';
import {TreeComponent} from 'angular-tree-component';
import {Category} from '../shared/category';
import {CategoryService} from '../shared/category.service';

@Component({
    selector: 'app-lists-update',
    templateUrl: './menus-update.component.html',
    styleUrls: ['./menus-update.component.css']
})
export class MenusUpdateComponent implements OnInit {

    list: Menu;
    reorder = false;
    editName = false;

    @Output() refresh = new EventEmitter();

    constructor(
        private listService: MenuService,
        private route: ActivatedRoute,
        public categoryService: CategoryService

    ) {
        this.list = new Menu();
        this.list.categories = [];
    }

    ngOnInit() {
        let id = this.route.snapshot.paramMap.get('id');
        this.get(Number(id));
    }

    get(id: number) {
        this.listService.get(id).subscribe(list => {
            this.list = list;
        });
    }

    addCategory() {
        let newCategory = new Category();
        newCategory.menu_id = this.list.id;
        newCategory.name = 'New Category';
        newCategory.menu_items = [];
        newCategory.children = [];
        newCategory.weight = this.list.categories.length;
        this.list.categories.push(newCategory);
    }

    saveOrder(categories) {
        let weight = 0;
        let save = [];
        categories.forEach(category => {
            category.weight = weight;
            save.push({weight: weight, id: category.id});
            weight ++;
        });

        this.categoryService.sort(save).subscribe(response => {
        });
    }

    stuff(event) {
        this.refresh.emit('update');
    }

    updateName() {
        this.listService.update(this.list).subscribe(response => {
            this.editName = false;
        });
    }



}
