import {Region} from '../../shared/region';

export class Agent {

    id: number;
    name: string;
    fname: string;
    lname: string;
    region: Region;
    representations: any;
    agency_id: number;
    region_id: number;
    phone: string;
    email: string;

}
