import {Component, Inject, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProducerService} from '../shared/producer.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Appellation} from '../../appellations/shared/appellation';
import {Producer} from '../shared/producer';
import {ProgressService} from '../../core/progress/progress.service';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {forEach} from '@angular/router/src/utils/collection';
import {RegionsService} from '../../shared/regions.service';
import {Region} from '../../shared/region';
import {debounceTime, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Agent} from '../../agents/shared/agent';
import {AgentsService} from '../../agents/shared/agents.service';

@Component({
    selector: 'app-producer-update',
    templateUrl: './producer-update.component.html',
    styleUrls: ['./producer-update.component.css']
})
export class ProducerUpdateComponent implements OnInit {

    public action: string;
    form: FormGroup;
    public regions: Region[];
    filteredAgents: Observable<Agent[]>;


    constructor(
        private producerService: ProducerService,
        public dialogRef: MatDialogRef<ProducerUpdateComponent>,
        @Inject(MAT_DIALOG_DATA) public producer: Producer,
        public progress: ProgressService,
        public fb: FormBuilder,
        public regionService: RegionsService,
        public agentService: AgentsService
    ) {

        this.form = this.fb.group({
            id: [''],
            name: [''],
            agentSearch: [''],
            representations: this.fb.array([
            ])
        });


        this.filteredAgents = this.form
            .get('agentSearch')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => this.agentService.autocomplete({query: value}))
            );

    }

    ngOnInit() {
        this.action = this.producer.id ? 'Edit' : 'Add';
        this.form.patchValue({
            name: this.producer.name,
            id: this.producer.id
        });
        for (let rep of this.producer.representations) {
            (<FormArray>this.form.get('representations')).push(this.fb.group({
                id: [rep.id],
                region: [rep.agent.region.name],
                agent: [rep.agent.name],
                agency: [rep.agent.agency.name],
                active: [true]
            }));
        }


    }

    save() {
        let producer = this.form.value;
        console.log(producer);
        this.progress.show();
        this.producerService.update(producer)
            .subscribe((response) => {
                this.progress.hide();
                if (response) {
                    this.dialogRef.close(response);
                }
            });
    }

    displayFn(user?): string | undefined {
        return user ? user.name : undefined;
    }

    selectAgent(event) {
        if (event && event.option && event.option.value) {
            let agent = event.option.value;
            (<FormArray>this.form.get('representations')).push(this.fb.group({
                id: [null],
                region: [agent.region.name],
                agent: [agent.name],
                agency: [agent.agency.name],
                active: [true],
                agent_id: [agent.id]
            }));
            this.form.get('agentSearch').setValue('');
        }
    }
}


