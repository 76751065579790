import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {MenusComponent} from './menus/menus.component';
import {MenusUpdateComponent} from './menus-update/menus-update.component';
import {MenusViewComponent} from './menus-view/menus-view.component';

const routes: Routes = [
    {path: 'menus/edit/:id', component: MenusUpdateComponent},
    {path: 'menus/view/:id', component: MenusViewComponent},
    {path: 'menus/:chain/:type', component: MenusComponent},
    {path: 'menus', component: MenusComponent},

];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class MenusRoutingModule {
}
