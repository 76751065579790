import {NgModule} from '@angular/core';
import {AppellationListComponent} from './appellation-list/appellation-list.component';
import {SharedModule} from '../shared/shared.module';
import {AppellationUpdateComponent} from './appellation-update/appellation-update.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {CoreModule} from '../core/core.module';

@NgModule({
    imports: [
        SharedModule,
        Ng2SmartTableModule
    ],
    // exports: [AppellationUpdateComponent],
    declarations: [AppellationListComponent, AppellationUpdateComponent],
    entryComponents: [AppellationUpdateComponent]

})
export class AppellationsModule {
}
