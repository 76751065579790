import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Product} from './product';
import {HttpBase} from '../../shared/http-base';
import {ExceptionService} from '../../core/exception.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends HttpBase<Product> {

    constructor(
        http: HttpClient,
        exception: ExceptionService
    ) {
        super(http, exception, '/products', false);
    }
}
