import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MenuService} from '../shared/menu.service';
import {Menu} from '../shared/menu';

@Component({
  selector: 'app-menus-view',
  templateUrl: './menus-view.component.html',
  styleUrls: ['./menus-view.component.css']
})
export class MenusViewComponent implements OnInit {

  public menu: Menu;


  constructor(
      private route: ActivatedRoute,
      private menuService: MenuService
  ) { }

    ngOnInit() {
        let id = this.route.snapshot.paramMap.get('id');
        this.get(Number(id));
        this.menu = new Menu();
    }

    get(id: number) {
        this.menuService.get(id).subscribe(menu => {
            this.menu = menu;
        });
    }

}
