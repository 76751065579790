import {Category} from './category';
import {Region} from '../../shared/region';


export class Menu {

    id: number;
    active: boolean;
    name: string;
    categories: Array<Category>;
    categoryList: Object;
    type: string;

    region: Region;
    chain: string;
}
