import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-category-reorder',
  templateUrl: './category-reorder.component.html',
  styleUrls: ['./category-reorder.component.css']
})
export class CategoryReorderComponent implements OnInit {

  @Input() categories;

  @Output() saveOrder: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  drop(event) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    this.saveOrder.emit(this.categories);
  }

}
