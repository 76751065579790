import {Producer} from '../../producers/shared/producer';
import {Appellation} from '../../appellations/shared/appellation';

export class Product {
    id: number;
    producer_id: number;
    appellation_id: number;
    name: string;
    short_name: string;
    fname: string;
    fshort_name;
    tap: boolean;
    glass: boolean;
    producer: Producer;
    appellation: Appellation;
    menu_items: Array<any>;
    type: string;
    description: string;
    chain: string;
    bottle: string;
    label: string;
}
