import { Injectable } from '@angular/core';
import {HttpBase} from '../../shared/http-base';
import {Glasspour} from './glasspour';
import {HttpClient} from '@angular/common/http';
import {ExceptionService} from '../../core/exception.service';

@Injectable({
  providedIn: 'root'
})
export class GlasspourService extends HttpBase<Glasspour> {

  constructor(
      http: HttpClient,
      exception: ExceptionService
  ) {
    super(http, exception, '/glasspours', false);
  }

}
