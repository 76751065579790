import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {LoggerService} from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {

  constructor(private logger: LoggerService) { }

    handleError<T>(operation, result?: T) {
        return (error: any): Observable<T> => {


            // TODO: better job of transforming error for user consumption
            // this.logger.error(`${operation} failed: ${error.message}`);


            // Let the app keep running by returning an empty result.
            return of(result as T);

        };

    }
}
