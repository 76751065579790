import { Injectable } from '@angular/core';
import {HttpBase} from '../../shared/http-base';
import {Agent} from './agent';
import {HttpClient} from '@angular/common/http';
import {ExceptionService} from '../../core/exception.service';

@Injectable({
  providedIn: 'root'
})
export class AgentsService extends HttpBase<Agent> {

  constructor(
      http: HttpClient,
      exception: ExceptionService
  ) {
    super(http, exception, '/agents', false);
  }

}
