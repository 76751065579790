import {Component, Inject, Input, OnInit} from '@angular/core';

import {MenuService} from '../shared/menu.service';
import {ToastrService} from 'ngx-toastr';
import {RegionsService} from '../../shared/regions.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-menus-add',
    templateUrl: './menus-add.component.html',
    styleUrls: ['./menus-add.component.css']
})
export class MenusAddComponent implements OnInit {

    regions;
    types;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<MenusAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private menuService: MenuService,
        private regionsService: RegionsService,
        private toastr: ToastrService,
        private fb: FormBuilder
    ) {

        this.form = this.fb.group({
            name: ['', Validators.required],
            region_id: ['', Validators.required],
            type: [this.data.type, Validators.required],
            french: [false],
            chain: [this.data.chain, Validators.required]
        });
    }

    ngOnInit() {
        this.regionsService.query().subscribe(response => {
            this.regions = response;
        });

        this.types = [{value: 'wine', name: 'Wine'}, {value: 'liquor', name: 'Liquor & Beer'}];
    }

    save() {
        if (!this.form.valid) {
            return;
        }
        let menu = this.form.value;

        this.menuService.update(menu).subscribe(response => {
            this.dialogRef.close(response);
        }, error => {
            this.toastr.error('Could not save menu');
        });
    }

}
